<template>
    <div class="footer-warp">
        <div class="layout">
          <div class="top flex-r justify-between">
            <div class="flex-r">
              <div class="item flex-c">
                  <span class="color-white font-20 mb-30">产品</span>
                  <router-link :to="{name:'Hyx'}" class="sub-title">慧银销</router-link>
                  <router-link :to="{name:'Hxf'}" class="sub-title">慧选付</router-link>
              </div>
              <div class="item flex-c">
                  <span class="color-white font-20 mb-30">解决方案</span>
                  <a class="sub-title" href="javascript:;">面向银行</a>
                  <a class="sub-title" href="javascript:;">面向渠道</a>
              </div>
              <div class="item flex-c">
                  <span class="color-white font-20 mb-30">公司</span>
                  <router-link :to="{name:'SuccessfulCase'}" class="sub-title">成功案例</router-link>
                  <router-link :to="{name:'About'}" class="sub-title">关于我们</router-link>
              </div>
              <div class="item flex-c">
                  <span class="color-white font-20 mb-30">联系我们</span>
                  <a class="sub-title" href="javascript:;">咨询热线：400-99-6153</a>
                  <a class="sub-title" href="javascript:;">联系邮箱：bd@sohobo.cn</a>
                  <a class="sub-title" href="javascript:;">公司地址：上海市徐汇区宜山路700号枫林科创园A4栋402室</a>
              </div>
            </div>
            
           <div class="item flex-c">
              <span class="color-white font-20 mb-30">关注我们</span>
              <div class="flex-r">
                <div class="flex-c mr-40">
                  <img class="official-img mb-10" src="@/assets/images/official.png" alt="">
                  <span class="official-name">慧银销公众号</span>
                </div>
                <div class="flex-c">
                  <img class="official-img mb-10" src="@/assets/images/minicode.png" alt="">
                  <span class="official-name">慧银销小程序</span>
                </div>
              </div>
           </div>
          </div>
          <div class="filings"><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备19026719号-1  公安备案号 31010402006267</a></div> 
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Footer'
  }
  </script>
  
  <style scoped lang="less">
  .footer-warp {
    .top {
      padding: 50px 0;
      .item {
        margin-right: 100px;
        &:last-child {
          margin-right: 0;
        }
        .official-img {
          width: 100px;
          height: 100px;
        }
        .official-name {
          color: #fff;
          text-align: center;
        }
      }
    }
    .sub-title {
      font-size: 16px;
      color: #7F7F80;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .filings {
      color: #7F7F80;
      text-align: center;
      font-size: 16px;
      padding-bottom: 20px;
      a {
        &:hover {
          color: #7F7F80;
        }
      }
    }
  }


    
  </style>
  