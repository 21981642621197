<template>
  <div class="successful-case-warp">
    <div class="banner">
      <a-carousel>
        <div class="banner-img">
          <div class="layout color-white flex-c items-center">
            <div class="title">深谙客户所需&nbsp;&nbsp;更懂行业所趋</div>
            <div class="sub-title">专业营销，助力企业快速发展</div>
          </div>
        </div>
      </a-carousel>
    </div>

    <div class="bank flex-c">
      <div class="title">众多银行、企业一致认可</div>
      <div class="layout nav flex-r mb-40">
        <div class="tab color-333" :class="{active: currentIndex === index}" :key="index"
          v-for="(item, index) in nav" @click="currentIndex = index">{{ item }}</div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 0">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                通联支付-通惠到手
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="mb-50 font-16">通联作为慧银销的贴牌商，做了自己的营销平台“通惠到手”，输出给各大银行，给各大银行做线上运营活动，助力银行促活拉新，帮助商家导流。</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">输出银行</div>
                  <div class="font-22">系统销售费</div>
                </div>
                <div class="item">
                  <div class="font-16">线上运营</div>
                  <div class="font-22">营销活动费</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right1"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 1">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                大连银行
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：借记卡开卡、睡眠卡促活、本地生活场景建设，政府促销费诉求 。</div>
              <div class="mb-50 font-16">输出模式：小程序+公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">立减金发放</div>
                  <div class="font-22">精准促活</div>
                </div>
                <div class="item">
                  <div class="font-16">营销活动运营</div>
                  <div class="font-22">新增办卡</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right2"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 2">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                交通银行
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：打造网点15分钟生活圈，促进银行卡获客和活跃，形成持卡人与商户BC联动。</div>
              <div class="mb-50 font-16">输出模式：公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">网点联动营销</div>
                  <div class="font-22">客户引流</div>
                </div>
                <div class="item">
                  <div class="font-16">引入优质商户</div>
                  <div class="font-22">特惠商圈</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right3"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 3">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                民生银行上海分行
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：打造网点3公里商户活动，促进银行卡获客和活跃，形成持卡人与商户BC联动。</div>
              <div class="mb-50 font-16">输出模式：公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">网点联动营销</div>
                  <div class="font-22">客户引流</div>
                </div>
                <div class="item">
                  <div class="font-16">引入优质商户</div>
                  <div class="font-22">特惠商圈</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right4"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 4">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                云南邮政金融
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：打造网点3公里商户活动，促进银行卡获客和活跃，形成持卡人与商户BC联动。</div>
              <div class="mb-50 font-16">输出模式：公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">网点联动营销</div>
                  <div class="font-22">客户引流</div>
                </div>
                <div class="item">
                  <div class="font-16">引入优质商户</div>
                  <div class="font-22">特惠商圈</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right5"></div>
      </div>
      <div class="scorll-warp">
        <div class="layout swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide1"></div>
          </div>
        </div>
        <div class="layout swiper-container" dir="rtl">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide2"></div>
          </div>
        </div>
        <div class="layout swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide3"></div>
          </div>
        </div>
        <div class="layout swiper-container" dir="rtl">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide4"></div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/dist/css/swiper.min.css' // 注意这里的引入
export default {
  name: 'SuccessfulCase',
  data() {
    return {
      duration: 5000,
      currentIndex: 0,
      nav:['通联支付', '大连银行', '交通银行', '民生银行', '邮政银行']
    }
  },
  mounted() {
    new Swiper('.swiper-container', {
      speed: 21500, //匀速时间
      autoplay: {
        delay: 0,
        stopOnLastSlide: true,
        disableOnInteraction: true,
      },
      loop: true
    });
  }
}
</script>

<style lang="less" scoped>
.swiper-container .swiper-wrapper{
    -webkit-transition-timing-function: linear; /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
}

.banner {
  .ant-carousel {
    .banner-img {
      width: 100%;
      height: 580px;
      background: url('~@/assets/images/success/banner.png') no-repeat;
      background-size: 100% 100%;

      .title {
        font-size: 52px;
        font-weight: 800;
        margin: 170px 0 46px 0;
      }

      .sub-title {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}

.bank {
  background-color: #fff;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .nav {
    border-bottom: 1px solid #F0F0F1;
    .tab {
      font-size: 18px;
      flex:1;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      &::after {
          position: absolute;
          content: '';
          bottom: 0;
          width: 100%;
          height: 3px;
          left: 0;
          transform: scaleX(0);
          background-color: #0089FA;
          transition: all 0.3s;
        }
      &.active {
        color: #0089FA;
        font-weight: bold;
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  .content {
    position: relative;
    height: 360px;
    .left {
      width: 50%;
      position: relative;
      background-color: #0089FA;
      .left-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        width: 600px;
        height: 100%;
        right: 0;
        padding-left: 48px;
        padding-right: 140px;
        .text {
          font-size: 22px;
          color: #fff;
          position: relative;
          .img {
            position: absolute;
            left: -48px;
            top: 0;
            width: 24px;
            height: 20px;
          }
        }
        .item {
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #82BFF8;
            margin-right: 10px;
          }
        }
        
      }
    }
    .right {
      width: 50%;
      height: 360px;
      background-repeat: no-repeat;
    }
    .right1 {
      background-image: url('~@/assets/images/bank/banner.png');
    }
    .right2 {
      background-image: url('~@/assets/images/bank/banner2.png');
    }
    .right3 {
      background-image: url('~@/assets/images/bank/banner3.png');
    }
    .right4 {
      background-image: url('~@/assets/images/bank/banner4.png');
    }
    .right5 {
      background-image: url('~@/assets/images/bank/banner5.png');
    }
  }

  .scorll-warp {
    overflow: hidden;
    margin-top: 60px;
    margin-bottom: 50px;
    .swiper-slide {
      height: 87px; 
      background-size: contain;
      background-repeat: no-repeat;
    }
    .swiper-slide1 {
      background-image:url('~@/assets/images/bank/1.png');
    }
    .swiper-slide2 {
      background-image:url('~@/assets/images/bank/2.png');
    }
    .swiper-slide3 {
      background-image:url('~@/assets/images/bank/3.png');
    }
    .swiper-slide4 {
      background-image:url('~@/assets/images/bank/4.png');
    }
  }


}
</style>