<template>
  <div class="solution-warp">
    <div class="banner">
      <a-carousel>
        <div class="banner-img">
          <div class="layout">
            <div class="color-primary title">银行本地生活营销平台</div>
            <div class="sub-title">丰富场景&nbsp;&nbsp;精准促活&nbsp;&nbsp;高效获客</div>
          </div>
        </div>
      </a-carousel>
    </div>

    <div class="product flex-c bg-white">
      <div class="title mb-50">银行核心诉求</div>
      <img class="layout bank-need-img" src="@/assets/images/solve/bank_need.png" alt="">
    </div>

    <div class="product flex-c bg-gray">
      <div class="title mb-20">慧银销本地生活营销平台</div>
      <div class="color-gary font-20 text-center mb-50">以全域商户为基础，叠加多种营销能力和工具组件，为银行打造本地生活营销聚合平台，帮助银行&商户共同经营客户</div>
      <img class="layout local-life-img" src="@/assets/images/solve/local_life.png" alt="">
    </div>

    <div class="bank flex-c">
      <div class="title">平台介绍</div>
      <div class="layout nav flex-r mb-40">
        <div class="tab color-333" :class="{active: currentIndex === index}" :key="index"
          v-for="(item, index) in nav" @click="currentIndex = index">{{ item }}</div>
      </div>
      <div class="layout content flex-c platform" v-show="currentIndex == 0">
        <img class="platform-img" src="@/assets/images/solve/platform1.png" alt="">
        <div class="flex-r justify-between mt-30">
          <div class="card">
            <div class="color-333 font-22 mt-20 mb-10">线上模块</div>
            <div class="color-gary font-16 text-center">银行可接入任意微信小程序，可做营销活动</div>
          </div>
          <div class="card">
            <div class="color-333 font-22 mt-20 mb-10">美团商户模块</div>
            <div class="color-gary font-16 text-center">商户数量多/质量优/覆盖广，自带商家权益+银行权益，提升用户粘性</div>
          </div>
          <div class="card">
            <div class="color-333 font-22 mt-20 mb-10">银行特惠商户模块</div>
            <div class="color-gary font-16 text-center">支持银行批量导入自收 单/联合收单商户，可做 营销活动</div>
          </div>
          <div class="card">
            <div class="color-333 font-22 mt-20 mb-10">分期商户模块</div>
            <div class="color-gary font-16 text-center">聚合优质线下分期商户，支持分期营销</div>
          </div>
          <div class="card">
            <div class="color-333 font-22 mt-20 mb-10">连锁品牌商户模块</div>
            <div class="color-gary font-16 text-center">支持50+连锁品牌，不断 新增。助力银行有效 拉新、持续促活 </div>
          </div>
        </div>
      </div>
      
      <div class="layout content flex-c platform" v-show="currentIndex == 1">
        <img class="platform-img" src="@/assets/images/solve/platform2.png" alt="">
        <div class="flex-r justify-between mt-30">
          <div class="flex-c items-center" v-for="item in icons" :key="item.src">
            <img class="icons mb-10" :src="item.src" alt="">
            <div class="font-16 color-333">{{ item.text }}</div>
          </div>
        </div>
      </div>

      <div class="layout content flex-c platform" v-show="currentIndex == 2">
        <img class="platform-img" src="@/assets/images/solve/platform3.png" alt="">
        <div class="flex-r justify-between mt-30">
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">到店买单，选择商户</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">领券</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">领取成功去买单</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">拉起场景小程序下单</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">选指定银行支付核券</div>
          </div>
        </div>
      </div>

      <div class="layout content flex-c platform" v-show="currentIndex == 3">
        <img class="platform-img" src="@/assets/images/solve/platform4.png" alt="">
        <div class="flex-r justify-between mt-30">
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">点击周边商户</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">领券</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">领取成功</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold" style="padding: 0 25px;">线下到店，<br />主扫/被扫买单</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">支付核券享受优惠</div>
          </div>
        </div>
      </div>

      <div class="layout content flex-c platform" v-show="currentIndex == 4">
        <img class="platform-img" src="@/assets/images/solve/platform5.png" alt="">
        <div class="flex-r justify-center between mt-30 items-center">
          <img src="@/assets/images/solve/icons/icons.png" alt="" style="width: 1000px;">
          <div class="font-weight-bold font-20 color-333 ml-20">更多商家</div>
        </div>
      </div>

      <div class="layout content flex-c platform" v-show="currentIndex == 5">
        <img class="platform-img" src="@/assets/images/solve/platform6.png" alt="">
        <div class="flex-r justify-between mt-30">
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">到店，点击分期商户</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">领取分期券</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">领取成功</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">拉起商户小程序会员码</div>
          </div>
          <div class="card2">
            <div class="color-333 font-20 font-weight-bold">点击分期付款</div>
          </div>
        </div>
      </div>
    </div>

    <div class="output flex-c bg-gray">
      <div class="title">输出模式</div>
      <div class="layout flex-r">
        <div class="nav-warp flex-c">
          <div class="nav" :class="{active : index == currentOutputNavIndex}" 
            @click="currentOutputNavIndex = index"
            v-for="(item, index) in outputNav" :key="item.title">
            <div class="flex-r items-center">
              <img class="icons" :src="index == currentOutputNavIndex ? item.activeSrc : item.src" alt="">
              <div class="sub-title">{{ item.title }}</div>
            </div>
            <div class="details">{{ item.details }}</div>
          </div>
        </div>
        <div class="right-content flex-1">
          <div class="h-100 flex-r items-center justify-center" v-show="currentOutputNavIndex == 0">
            <img style="width: 800px; height: 664px;" src="@/assets/images/solve/output1.png" alt="">
          </div>

          <div class="h-100 flex-c items-center justify-center" v-show="currentOutputNavIndex == 1">
            <img style="width: 662px; height: 612px;" src="@/assets/images/solve/output2.png" alt="">
            <div class="flex-r font-16 color-primary mt-30" style="width: 662px;">
              <div class="flex-1 text-center" style="margin-right: 100px;">银行APP配置本地生活入口</div>
              <div class="flex-1 text-center">点击直跳小程序首页模块</div>
            </div>
          </div>

          <div class="h-100 flex-c items-center justify-center" v-show="currentOutputNavIndex == 2">
            <img style="width: 662px; height: 612px;" src="@/assets/images/solve/output3.png" alt="">
            <div class="flex-r font-16 color-primary mt-30" style="width: 662px;">
              <div class="flex-1 text-center" style="margin-right: 100px;">银行公众号配置本地生活入口</div>
              <div class="flex-1 text-center">用户点击拉起小程序首页模块</div>
            </div>
          </div>

          <div class="h-100 flex-c items-center justify-center" v-show="currentOutputNavIndex == 3">
            <img style="width: 800px; height: 545px;" src="@/assets/images/solve/output4.png" alt="">
            <div class="flex-r font-16 color-primary font-weight-bold mt-30" style="width: 800px;">
              <div class="flex-c text-center ml-30" >
                <div class="font-20 mb-10">模式一</div>
                <div >单独小程序整体输出，<br>小程序主体可支持银行主体，<br>也可使用驰曼主体</div>
              </div>
              <div class="flex-c text-center" style="margin-left: 150px;">
                <div class="font-20 mb-10">模式二</div>
                <div >支持输出嵌入银行在运营小程序，<br>用户通过银行在运营小程序点击相关入口拉起</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="marketing flex-c">
      <div class="title">营销能力叠加</div>
      <div class="text-center font-22 color-gary">基于慧银销线上线下场景模块，叠加聚合ATUM等支付营销能力，面向银行提供一站式<br>营销运营服务，构建本地生活场景生态，经营私域流</div>
      <div class="layout flex-r justify-between mt-50">
        <div class="card-warp flex-c items-center">
          <div class="card flex-r items-center justify-center mb-30">
            支持针对输出具体场景开展微信收银台<br>“支付立减”覆盖更多目标人群。  
          </div>
          <div class="flex-c items-center line-warp">
            <div class="circle flex-c items-center justify-center mb-40">
              <img src="@/assets/images/solve/marketing1.png" alt="">
              <div class="font-22 color-white font-weight-bold">支付立减</div>
            </div>
            <div class="btn flex-r items-center justify-center mb-30">折扣</div>
            <div class="btn flex-r items-center justify-center mb-30">满立减</div>
            <div class="btn flex-r items-center justify-center">随机立减</div>
            <div class="line"></div>
          </div>
        </div>

        <div class="card-warp flex-c items-center">
          <div class="card flex-r items-center justify-center mb-30">
            通过“发、领、抽” 多种方式投放，<br>促进银行APP/公众号/小程序/线下网点<br>用户拉新、留存及月活提升。  
          </div>
          <div class="flex-c items-center line-warp">
            <div class="circle flex-c items-center justify-center mb-40">
              <img src="@/assets/images/solve/marketing2.png" alt="">
              <div class="font-22 color-white font-weight-bold">优惠券</div>
            </div>
            <div class="btn flex-r items-center justify-center mb-30">满减券</div>
            <div class="btn flex-r items-center justify-center">折扣券</div>
            <div class="line"></div>
          </div>
        </div>

        <div class="card-warp flex-c items-center">
          <div class="card flex-r items-center justify-center mb-30">
            通过对达标用户奖励、任务派券等方式， <br>通过白名单发券模式帮助银行精准促活  
          </div>
          <div class="flex-c items-center line-warp">
            <div class="circle flex-c items-center justify-center mb-40">
              <img src="@/assets/images/solve/marketing3.png" alt="">
              <div class="font-22 color-white font-weight-bold">精准促活</div>
            </div>
            <div class="btn flex-r items-center justify-center mb-30">达标有礼</div>
            <div class="btn flex-r items-center justify-center">面对面发券</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="pay-warp flex-r justify-center items-center">
        <div class="font-22 font-weight-bold">聚合营销服务 ：</div>
        <img src="@/assets/images/solve/pay1.png" alt="">
        <img src="@/assets/images/solve/pay2.png" alt="">
        <img src="@/assets/images/solve/pay3.png" alt="">
        <img src="@/assets/images/solve/pay4.png" alt="">
        <img src="@/assets/images/solve/pay5.png" alt="">
      </div>
    </div>

    <div class="case flex-c">
      <div class="title">合作案例-大连银行</div>
      <div class="layout flex-c " style="background-color: rgba(0, 0, 0, 0.2);">
        <div class="flex-r justify-between" v-show="currentBottomNavIndex == 0">
          <div class="flex-1 flex-r justify-center" style="padding-top: 60px;">
            <img style="width: 866px; height: 430px;" src="@/assets/images/solve/case1.png" alt="">
          </div>
          <div class="right-text" style="padding: 60px 20px;">
            <div class="item font-weight-bold color-white mb-20">
              <div class="flex-r items-center mb-10">
                <div class="diamond"></div>
                <div class="font-22 ml-10">银行对接部门: </div>
              </div>
              <div class="font-18"> 地方性银行总行 — 零售部</div>
            </div>
            <div class="item font-weight-bold color-white mb-20">
              <div class="flex-r items-center mb-10">
                <div class="diamond"></div>
                <div class="font-22 ml-10">银行合作诉求: </div>
              </div>
              <div class="font-18">借记卡开卡、睡眠卡促活、本地生活场景建设，政府促</div>
            </div>
            <div class="item font-weight-bold color-white">
              <div class="flex-r items-center mb-10">
                <div class="diamond"></div>
                <div class="font-22 ml-10">合作内容: </div>
              </div>
              <div class="font-18">1.公众号输出：美团场景+饿了么场景输出 <br> 2. 叠加营销活动开展</div>
            </div>
          </div>
        </div>
        <div class="flex-r justify-between" v-show="currentBottomNavIndex == 1">
          <div class="flex-1 flex-r justify-center" style="padding-top: 60px;">
            <img style="width: 866px; height: 430px;" src="@/assets/images/solve/case2.png" alt="">
          </div>
          <div class="right-text" style="padding: 60px 20px;">
            <div class="item font-weight-bold color-white mb-20">
              <div class="flex-r items-center mb-10">
                <div class="diamond"></div>
                <div class="font-22 ml-10">Step1: </div>
              </div>
              <div class="font-18">12月5日，开展行内发布会，面向全行发布本地生活特惠平台—码上享。</div>
            </div>
          </div>
        </div>
        <div class="flex-r justify-between" v-show="currentBottomNavIndex == 2">
          <div class="flex-1 flex-r justify-center" style="padding-top: 60px;">
            <img style="width: 866px; height: 430px;" src="@/assets/images/solve/case3.png" alt="">
          </div>
          <div class="right-text" style="padding: 60px 20px;">
            <div class="item font-weight-bold color-white mb-20">
              <div class="flex-r items-center mb-10">
                <div class="diamond"></div>
                <div class="font-22 ml-10">Step2: </div>
              </div>
              <div class="font-18">基于码上享场景，持续开展营销活动,通过公众号推文、银行微信群发等形式推广。</div>
            </div>
            <div class="item font-weight-bold color-white mb-20">
              <div class="flex-r items-center mb-10">
                <div class="diamond"></div>
                <div class="font-22 ml-10">运营效果: </div>
              </div>
              <div class="font-18">1.每次活动，每日限额300名，上线半小时优惠券全部抢完。美团核销率 67%，饿了么核销率 84%。<br>2.持续的营销活动运营，带动了更多借记卡新增办卡，如借助码上享活动在某公司食堂开展办卡活动，单日新增20户+。<br>3.促进了银行公众号粉丝的活跃。</div>
            </div>
          </div>
        </div>
        <div class="bottom-nav flex-r justify-between">
          <div class="nav" :class="{active: currentBottomNavIndex == index }" 
            v-for="(item, index) in bottomNav" :key="index" @click="currentBottomNavIndex = index">{{ item }}</div>
        </div>
      </div>
    </div>

    <div class="cooperate flex-c">
      <div class="title">为银行带来价值</div>
      <div class="layout card-warp flex-r justify-between">
        <div class="card flex-c ">
          <div class="flex-r items-center justify-center mb-10">
            <img class="icon" src="@/assets/images/hyx/icon1.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">丰富场景</span>
          </div>
          <div class="font-16 color-gary">丰富银行权益优惠场景，助力银行打造本地 生活平台，服务存量持卡人，提升存量客户 活跃。同时提升银行公众号APP粉丝量和用 户活</div>
        </div>
        <div class="card flex-c">
          <div class="flex-r items-center justify-center mb-10">
            <img class="icon" src="@/assets/images/hyx/icon2.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">精准促活</span>
          </div>
          <div class="font-16 color-gary">支持基于本地生活服务特惠场景叠加营销活动，精准促活睡眠户或指定用户，有效触达优质客户，促进银行卡活跃。</div>
        </div>
        <div class="card flex-c">
          <div class="flex-r items-center justify-center mb-10">
            <img class="icon" src="@/assets/images/hyx/icon3.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">高效获客</span>
          </div>
          <div class="font-16 color-gary">支持银行打造商圈特惠商户专题，助力银行 特惠商圈经营，帮助银行进行获客，实现开 户、拉存款等指标。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Solution',
  data() {
    return {
      icons: [{
        src: require('@/assets/images/solve/icons/1.png'),
        text:'美团团购'
      },{
        src:  require('@/assets/images/solve/icons/2.png'),
        text:'饿了么'
      },{
        src:  require('@/assets/images/solve/icons/3.png'),
        text:'美团外卖'
      },{
        src:  require('@/assets/images/solve/icons/4.png'),
        text:'特价电影'
      },{
        src:  require('@/assets/images/solve/icons/5.png'),
        text:'会员一码付'
      },{
        src: require('@/assets/images/solve/icons/6.png'),
        text:'滴滴打车'
      },{
        src: require('@/assets/images/solve/icons/7.png'),
        text:'滴滴加油'
      },{
        src: require('@/assets/images/solve/icons/8.png'),
        text:'酒店民宿'
      },
      {
        src: require('@/assets/images/solve/icons/9.png'),
        text:'景区门票'
      },{
        src: require('@/assets/images/solve/icons/10.png'),
        text:'京东分期'
      },{
        src: require('@/assets/images/solve/icons/11.png'),
        text:'全家'
      }],
      currentIndex: 0,
      nav: ['5大营销场景', '线上高频场景', '美团商户场景', '银行特惠商户场景', '连锁&商超场景', '分期商户场景'],
      currentOutputNavIndex:0,
      outputNav:[{
        src: require('@/assets/images/solve/nav1.png'),
        activeSrc: require('@/assets/images/solve/nav1_on.png'),
        title: '定制化输出模式',
        details:'一键输出，轻装上线，快速打造银行 自有本地生活平台-银行版”小美团”'
      },{
        src: require('@/assets/images/solve/nav2.png'),
        activeSrc: require('@/assets/images/solve/nav2_on.png'),
        title: '银行APP输出',
        details:'支持整体平台或单场景输出银行APP， 用户登录银行APP，用户点击配置入 口，直接跳转小程序首页或指定具体 场景页面。丰富银行'
      },{
        src: require('@/assets/images/solve/nav3.png'),
        activeSrc: require('@/assets/images/solve/nav3_on.png'),
        title: '银行微信公众号输出',
        details:'支持整体平台或单场景输出银行微信 公众号，银行只需配置菜单即可实现 一键上线。'
      },{
        src: require('@/assets/images/solve/nav4.png'),
        activeSrc: require('@/assets/images/solve/nav4_on.png'),
        title: '银行微信小程序输出',
        details:'支持整体平台或单场景输出银行微信 小程序，支持银行已有小程序嵌入或 单独小程序输出。'
        }],
      currentBottomNavIndex: 0,
      bottomNav:['输出方式', '运营 Step1', '运营 Step2']
    }
  },
}
</script>

<style lang="less" scoped>
.banner {
  .ant-carousel {
    .banner-img {
      width: 100%;
      height: 580px;
      background: url('~@/assets/images/solve/banner@2x.png') no-repeat;
      background-size: 100% 100%;

      .title {
        font-size: 52px;
        font-weight: 800;
        margin: 170px 0 46px 0;
      }

      .sub-title {
        font-size: 30px;
        font-weight: 500;
        color: #2C3A50;
      }
    }
  }
}

.product {
  .title {
    margin-top: 80px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .bank-need-img {
    margin-bottom: 100px;
  }
  .local-life-img {
    margin-bottom: 80px;
  }
}

.bank {
  background-color: #fff;
  padding-bottom: 100px;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .nav {
    border-bottom: 1px solid #F0F0F1;
    .tab {
      font-size: 18px;
      flex:1;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      &::after {
          position: absolute;
          content: '';
          bottom: 0;
          width: 100%;
          height: 3px;
          left: 0;
          transform: scaleX(0);
          background-color: #0089FA;
          transition: all 0.3s;
        }
      &.active {
        color: #0089FA;
        font-weight: bold;
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  .content {
    .latform-img {
      width: 100%;
      height: 360px;
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;
      border: 1px dashed #ccc;
      width: 220px;
      height: 150px;
      padding:  0 20px;
    }

    .card2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px dashed #ccc;
      width: 220px;
      height: 64px;
      line-height: 24px;
      text-align: center;
    }
    
    .icons {
      width: 60px;
      height: 60px;
    }
  }

}

.output {
  background-color: #F5F7FA;
  padding-bottom: 100px;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }

  .nav-warp {
    .nav {
      background-color: #fff;
      width: 330px;
      padding: 30px 25px;
      margin-bottom: 20px;
      border-radius: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        background: #0089FA;
        box-shadow: 0px 10px 22px 2px rgba(70,159,251,0.26);
        .sub-title, .details {
          color: #fff;
        }
      }
      .icons {
        width: 30px;
        height: 30px;
      }
      .sub-title {
        color: #111111;
        font-size: 22px;
        font-weight: bold;
        margin-left: 10px;
      }
      .details {
        margin-top: 20px;
        font-size: 16px;
        color: #666;
      }
    }
    
  }

  .right-content {
    border: 2px solid #AED8FB;
    border-radius: 0px 10px 10px 0px;
  }
}

.marketing {
  background-color: #fff;
  padding-bottom: 100px;
  .title {
    margin-top: 80px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .line-warp {
    position: relative;
    z-index: 0;
    .line {
      position: absolute;
      top:0;
      height: 450px;
      border-left: 2px dashed #ccc;
      z-index: -1;
    }
  }
  .card {
    width: 380px;
    height: 100px;
    background-image: linear-gradient(0deg, #fff, #f3f5f8);
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    border-radius: 4px;
    border: 2px solid #fff;
  }
  .circle {
    width: 130px;
    height: 130px;
    background: #0089FA;
    box-shadow: 0px 10px 22px 2px rgba(70,159,251,0.26);
    border-radius: 50%;
    img {
      width: 46px;
      height: 46px;
    }
  }
  .btn {
    width: 200px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 25px;
    color: #0089FA;
    border: 2px solid #0089FA;
    font-size: 20px;
  }
  .pay-warp {
    margin-top: 90px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.case {
  height: 814px;
  background: url('~@/assets/images/solve/case-bg.png') no-repeat;
  background-size: cover;
  .diamond {
    width: 10px;
    height: 10px;
    background-color: #0089FA;
    transform: rotate(45deg);
  }
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #fff;
    text-align: center;
  }
  .right-text {
    width: 280px;
    height: 546px;
    border-radius: 0px 10px 0px 0px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .bottom-nav {
    padding: 14px 20px;
    height: 80px;
    border-radius: 10px 10px 0px 0px;
    background-color: rgba(0, 0, 0, 0.2);
    .nav {
      flex: 1;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      &.active {
        background-color: #0089FA;
      }
    }
  }
}

.cooperate {
  background-color: #fff;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }

  .card-warp {
      margin-bottom: 90px;
      .card {
        width: 380px;
        height: 200px;
        background-image: linear-gradient(0deg, #fff, #f3f5f8);
        box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
        border-radius: 4px;
        border: 2px solid #FFFFFF;
        padding: 30px 30px 0;
        .icon {
          width: 30px;
          height: 30px;
        }
    }
  }
}
</style>