import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Hyx from '@/views/Hyx.vue'
import Hxf from '@/views/Hxf.vue'
import Solution from '@/views/Solution.vue'
import SuccessfulCase from '@/views/SuccessfulCase.vue'
import About from '@/views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Hyx',
    name: 'Hyx',
    component: Hyx
  },
  {
    path: '/Hxf',
    name: 'Hxf',
    component: Hxf
  },
  {
    path: '/Solution',
    name: 'Solution',
    component: Solution
  },
  {
    path: '/SuccessfulCase',
    name: 'SuccessfulCase',
    component: SuccessfulCase
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
})

export default router
