<template>
  <div id="app">
    <a-layout id="layout">
      <a-layout-header>
        <Header></Header>
      </a-layout-header>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
      <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from '@/components/Header/index.vue'
import Footer from '@/components/Footer/index.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less">

#layout {
  .ant-layout-header {
    padding: 0;
    height: 80px;
    background-color: #fff;

  }
  .ant-layout-footer {
    background-color: #17181B;
    padding: 0;
  }
}
</style>
