var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"successful-case-warp"},[_c('div',{staticClass:"banner"},[_c('a-carousel',[_c('div',{staticClass:"banner-img"},[_c('div',{staticClass:"layout color-white flex-c items-center"},[_c('div',{staticClass:"title"},[_vm._v("深谙客户所需  更懂行业所趋")]),_c('div',{staticClass:"sub-title"},[_vm._v("专业营销，助力企业快速发展")])])])])],1),_c('div',{staticClass:"bank flex-c"},[_c('div',{staticClass:"title"},[_vm._v("众多银行、企业一致认可")]),_c('div',{staticClass:"layout nav flex-r mb-40"},_vm._l((_vm.nav),function(item,index){return _c('div',{key:index,staticClass:"tab color-333",class:{active: _vm.currentIndex === index},on:{"click":function($event){_vm.currentIndex = index}}},[_vm._v(_vm._s(item))])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 0),expression:"currentIndex == 0"}],staticClass:"content flex-r"},[_vm._m(0),_c('div',{staticClass:"right right1"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 1),expression:"currentIndex == 1"}],staticClass:"content flex-r"},[_vm._m(1),_c('div',{staticClass:"right right2"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 2),expression:"currentIndex == 2"}],staticClass:"content flex-r"},[_vm._m(2),_c('div',{staticClass:"right right3"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 3),expression:"currentIndex == 3"}],staticClass:"content flex-r"},[_vm._m(3),_c('div',{staticClass:"right right4"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == 4),expression:"currentIndex == 4"}],staticClass:"content flex-r"},[_vm._m(4),_c('div',{staticClass:"right right5"})]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left-content color-white"},[_c('div',{staticClass:"text mb-20"},[_vm._v(" 通联支付-通惠到手 "),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/bank/comma.png"),"alt":""}})]),_c('div',{staticClass:"mb-50 font-16"},[_vm._v("通联作为慧银销的贴牌商，做了自己的营销平台“通惠到手”，输出给各大银行，给各大银行做线上运营活动，助力银行促活拉新，帮助商家导流。")]),_c('div',{staticClass:"flex-r items-center justify-between"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("输出银行")]),_c('div',{staticClass:"font-22"},[_vm._v("系统销售费")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("线上运营")]),_c('div',{staticClass:"font-22"},[_vm._v("营销活动费")])]),_c('div',{staticClass:"item"},[_c('span'),_c('span'),_c('span')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left-content color-white"},[_c('div',{staticClass:"text mb-20"},[_vm._v(" 大连银行 "),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/bank/comma.png"),"alt":""}})]),_c('div',{staticClass:"font-16"},[_vm._v("合作诉求：借记卡开卡、睡眠卡促活、本地生活场景建设，政府促销费诉求 。")]),_c('div',{staticClass:"mb-50 font-16"},[_vm._v("输出模式：小程序+公众号")]),_c('div',{staticClass:"flex-r items-center justify-between"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("立减金发放")]),_c('div',{staticClass:"font-22"},[_vm._v("精准促活")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("营销活动运营")]),_c('div',{staticClass:"font-22"},[_vm._v("新增办卡")])]),_c('div',{staticClass:"item"},[_c('span'),_c('span'),_c('span')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left-content color-white"},[_c('div',{staticClass:"text mb-20"},[_vm._v(" 交通银行 "),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/bank/comma.png"),"alt":""}})]),_c('div',{staticClass:"font-16"},[_vm._v("合作诉求：打造网点15分钟生活圈，促进银行卡获客和活跃，形成持卡人与商户BC联动。")]),_c('div',{staticClass:"mb-50 font-16"},[_vm._v("输出模式：公众号")]),_c('div',{staticClass:"flex-r items-center justify-between"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("网点联动营销")]),_c('div',{staticClass:"font-22"},[_vm._v("客户引流")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("引入优质商户")]),_c('div',{staticClass:"font-22"},[_vm._v("特惠商圈")])]),_c('div',{staticClass:"item"},[_c('span'),_c('span'),_c('span')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left-content color-white"},[_c('div',{staticClass:"text mb-20"},[_vm._v(" 民生银行上海分行 "),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/bank/comma.png"),"alt":""}})]),_c('div',{staticClass:"font-16"},[_vm._v("合作诉求：打造网点3公里商户活动，促进银行卡获客和活跃，形成持卡人与商户BC联动。")]),_c('div',{staticClass:"mb-50 font-16"},[_vm._v("输出模式：公众号")]),_c('div',{staticClass:"flex-r items-center justify-between"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("网点联动营销")]),_c('div',{staticClass:"font-22"},[_vm._v("客户引流")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("引入优质商户")]),_c('div',{staticClass:"font-22"},[_vm._v("特惠商圈")])]),_c('div',{staticClass:"item"},[_c('span'),_c('span'),_c('span')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"left-content color-white"},[_c('div',{staticClass:"text mb-20"},[_vm._v(" 云南邮政金融 "),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/bank/comma.png"),"alt":""}})]),_c('div',{staticClass:"font-16"},[_vm._v("合作诉求：打造网点3公里商户活动，促进银行卡获客和活跃，形成持卡人与商户BC联动。")]),_c('div',{staticClass:"mb-50 font-16"},[_vm._v("输出模式：公众号")]),_c('div',{staticClass:"flex-r items-center justify-between"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("网点联动营销")]),_c('div',{staticClass:"font-22"},[_vm._v("客户引流")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"font-16"},[_vm._v("引入优质商户")]),_c('div',{staticClass:"font-22"},[_vm._v("特惠商圈")])]),_c('div',{staticClass:"item"},[_c('span'),_c('span'),_c('span')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scorll-warp"},[_c('div',{staticClass:"layout swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide1"})])]),_c('div',{staticClass:"layout swiper-container",attrs:{"dir":"rtl"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide2"})])]),_c('div',{staticClass:"layout swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide3"})])]),_c('div',{staticClass:"layout swiper-container",attrs:{"dir":"rtl"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide4"})])])])
}]

export { render, staticRenderFns }