<template>
  <div class="about-warp">
    <div class="banner">
      <a-carousel >
        <div class="banner-img">
          <div class="layout">
            <div class="color-primary title">驰曼(上海)信息科技有限公司</div>
            <div class="sub-title">助力企业降本增效，解决实际问题</div>
          </div>
        </div>
      </a-carousel>
    </div>

    <div class="company flex-c bg-white">
      <div class="title">公司简介</div>
      <div class="layout flex-r justify-between">
        <div class="text color-333 font-18">
          <p class="mb-20">驰曼(上海)信息科技有限公司成立于2019年，核心团队来自知名金融支付机构及互联网大厂，慧银销和慧选付是驰曼旗下品牌</p>
          <p class="mb-20">驰曼科技核心业务是基于企业经营场景为企业提供数字化解决方案，助力企业降本增效，解决实际问题。经过近几年发展，公司已成长为国家级高新技术企业及科技型中小企业，产品全部自主研发并取得多项计算机著作权，并先后与中国银联，招商银行，平安银行，南京银行,通联支付等多家银行及金融持牌机构达成深度合作关系。</p>
          <p>慧银销是驰曼科技面向银行及中大型企业打造的数字营销与权益服务平台，一站式提供活动发放能力，场景核销能力，票税处理能力，代运营能力和营销导流能力；慧选付是面向个人打造本地生活聚合优惠支付平台，依托银行和大型企业营销活动与权益，为个人用户提供本地生活折上折服务。</p>
        </div>
        <img class="company-img" src="@/assets/images/about/company.png" alt="">
      </div>
    </div>

    <div class="company flex-c bg-gray">
      <div class="title">获得多项资质证书，专业能力备受认可</div>
      <div class="layout flex-r justify-between font-18 color-primary">
        <div class="item flex-c items-center">
          <img class="icon" src="@/assets/images/about/icon1.png" alt="">
          <span >国家高新技术企业</span>
        </div>
        <div class="item flex-c items-center">
          <img class="icon" src="@/assets/images/about/icon2.png" alt="">
          <span >多项计算机著作权</span>
        </div>
        <div class="item flex-c items-center">
          <img class="icon" src="@/assets/images/about/icon3.png" alt="">
          <span >慧银销专利证书</span>
        </div>
        <div class="item flex-c items-center">
          <img class="icon" src="@/assets/images/about/icon4.png" alt="">
          <span >信息安全管理体系认证</span>
        </div>
        <div class="item flex-c items-center">
          <img class="icon" src="@/assets/images/about/icon5.png" alt="">
          <span >网络安全等级保护</span>
        </div>
      </div>
    </div>

    <div class="company flex-c bg-white">
      <div class="title">联系我们</div>
      <div class="layout flex-r justify-between">
        <div class="flex-c justify-center text color-333 font-22">
          <div class="flex-r mb-30">
            <div class="label">咨询热线：</div>
            <div class="right">400-99-6153</div>
          </div>
          <div class="flex-r mb-30">
            <div class="label">联系邮箱：</div>
            <div class="right">bd@sohobo.cn</div>
          </div>
          <div class="flex-r">
            <div class="label">公司地址：</div>
            <div class="right">上海市徐汇区宜山路700号枫林科创园A4栋402室</div>
          </div>
        </div>
        <img class="company-img" style="width: 600px;height: 500px;" src="@/assets/images/about/map.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style scoped  lang="less">
.banner {
  .ant-carousel {
    .banner-img {
      width: 100%;
      height: 580px;
      background: url('~@/assets/images/about/banner.png') no-repeat;
      background-size: 100% 100%;

      .title {
        font-size: 52px;
        font-weight: 800;
        margin: 170px 0 46px 0;
      }

      .sub-title {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}

.company {
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .layout {
    margin-bottom: 100px;
  }
  .text {
    line-height: 30px;
  }
  .company-img {
    width: 470px;
    height: 353px;
    margin-left: 50px;
  }
  p {
    text-indent: 2em;
  }

  .icon {
    width: 115px;
    height: 117px;
  }

  .label {
    width: 140px;
    flex-shrink: 0;
  }

  .right {
    flex: 1;
    text-align: left;
    display: flex;
  }

}
</style>