<template>
    <div class="header-warp">
        <div class="layout header flex-r justify-between items-center">
            <img class="login" src="@/assets/images/logo.png" alt="">
            <div class="flex-r">
              <div class="nav">
                <router-link :to="{name:'Home'}" exact class="ant-dropdown-link ">首页</router-link>
                <router-link :to="{name:'Hyx'}" class="ant-dropdown-link">慧银销</router-link>
                <!-- <router-link :to="{name:'Hxf'}" class="ant-dropdown-link">慧选付</router-link> -->
                <a-dropdown>
                  <router-link :to="{name:'Solution'}" class="ant-dropdown-link">解决方案</router-link>
                </a-dropdown>
                <router-link :to="{name:'SuccessfulCase'}" class="ant-dropdown-link">成功案例</router-link>
                <router-link :to="{name:'About'}" class="ant-dropdown-link">关于我们</router-link>
              </div>
              <div class="tel-warp flex-r items-center">
                <img class="tel-img" src="@/assets/images/tel.png" alt="">
                <span class="color-primary font-16 ml-10">400-999-6153</span>
              </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'Header'
  }
  </script>
  
  <style scoped lang="less">
  .header-warp {
    height: 100%;
    .login {
      width: 90px;
      height: 30px;
    }

    .tel-img {
      width: 16px;
      height: 16px;
    }
    .ant-dropdown-link {
      font-size: 16px;
      font-weight: 500;
      color: #111;
      margin-right: 40px;
      position: relative;
      &:hover {
        color: #0089FA;
      }
      &.router-link-active {
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          background-color: #0089FA;
          left: 0;
          right: 0;
          bottom: -10px;

        }
        color: #0089FA;
      }
    }
    .header {
      height: 100%;
    }
  }
    
  </style>
  