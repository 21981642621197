<template>
  <div class="hyx-warp">
    <div class="banner">
      <a-carousel>
        <div class="banner-img">
          <div class="layout">
            <div class="color-primary title">数字营销与权益服务平台</div>
            <div class="sub-title">现金红包活动&消费红包活动</div>
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="hongbao flex-c">
      <div class="title">致力于数字营销与权益服务</div>
      <div class="layout card-warp flex-r justify-center">
        <div class="card flex-c font-16 color-gary">
          <div class="header flex-c items-center justify-center color-white">
            <div class="flex-r items-center mb-10">
              <img src="@/assets/images/hyx/hongbao.png" alt="">
              <div class="font-22 ml-10">现金红包</div>
            </div>
            <div>通过微信/支付宝发放至零钱包</div>
          </div>
          <div class="flex-c items-center">
            <div class="flex-r items-baseline mt-50">
              <span>现金红包</span>
              <span class="color-primary font-38 font-weight-bold">***元</span>
            </div>
            <div class="line mt-30 mb-30"></div>
            <div>
              <div class="flex-r items-center mb-20">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">积分兑换现金</div>
              </div>
              <div class="flex-r items-center mb-20">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">做任务赚现金</div>
              </div>
              <div class="flex-r items-center mb-20">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">抽奖兑换现金</div>
              </div>
              <div class="flex-r items-center">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">更多场景</div>
              </div>
            </div>

            <div class="flex-r items-center  mt-30">
              <img class="right-icon" src="@/assets/images/hyx/right.png" alt="">
              <div class="color-primary ml-10">输出控台版和接口版</div>
            </div>
          </div>
        </div>
        <div class="card flex-c font-16 color-gary" style="margin-left: 110px;">
          <div class="header flex-c items-center justify-center color-white">
            <div class="flex-r items-center mb-10">
              <img src="@/assets/images/hyx/hongbao2.png" alt="">
              <div class="font-22 ml-10">消费红包</div>
            </div>
            <div>通过微信/支付宝消费立减</div>
          </div>
          <div class="flex-c items-center">
            <div class="flex-r items-baseline mt-50 color-blue">
              <span>消费立减</span>
              <span class="font-38 font-weight-bold">***元</span>
            </div>
            <div class="line mt-30 mb-30"></div>
            <div>
              <div class="flex-r items-center mb-20">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">商家满减促销活动</div>
              </div>
              <div class="flex-r items-center mb-20">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">银行持卡人营销活动</div>
              </div>
              <div class="flex-r items-center mb-20">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">积分/抽奖/奖励</div>
              </div>
              <div class="flex-r items-center">
                <img class="dui" src="@/assets/images/hyx/dui.png" alt="">
                <div class="ml-10">更多场景</div>
              </div>
            </div>

            <div class="flex-r items-center  mt-30">
              <img class="right-icon" src="@/assets/images/hyx/right.png" alt="">
              <div class="color-primary ml-10">输出小程序或H5</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom flex-c items-center font-16 color-white">
        <div>慧银销平台为合作商家开展营销活动提供</div>
        <div class="font-22 mb-40">发放能力+核销能力+开票能力+运营能力</div>
      </div>
    </div>

    <div class="product flex-c">
      <div class="title">慧银销平台展示</div>
      <img src="@/assets/images/hyx/product.png" alt="">
    </div>

    <div class="cooperate flex-c">
      <div class="title">合作价值</div>
      <div class="layout card-warp flex-r justify-between">
        <div class="card flex-c ">
          <div class="flex-r items-center justify-center mb-10">
            <img class="icon" src="@/assets/images/hyx/icon1.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">丰富场景</span>
          </div>
          <div class="font-16 color-gary">丰富银行权益优惠场景，助力银行打造本地 生活平台，服务存量持卡人，提升存量客户 活跃。同时提升银行公众号APP粉丝量和用 户活</div>
        </div>
        <div class="card flex-c">
          <div class="flex-r items-center justify-center mb-10">
            <img class="icon" src="@/assets/images/hyx/icon2.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">精准促活</span>
          </div>
          <div class="font-16 color-gary">支持基于本地生活服务特惠场景叠加营销活动，精准促活睡眠户或指定用户，有效触达优质客户，促进银行卡活跃。</div>
        </div>
        <div class="card flex-c">
          <div class="flex-r items-center justify-center mb-10">
            <img class="icon" src="@/assets/images/hyx/icon3.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">高效获客</span>
          </div>
          <div class="font-16 color-gary">支持银行打造商圈特惠商户专题，助力银行 特惠商圈经营，帮助银行进行获客，实现开 户、拉存款等指标。</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Hyx',
}
</script>

<style lang="less" scoped>
.banner {
  .ant-carousel {
    .banner-img {
      width: 100%;
      height: 580px;
      background: url('~@/assets/images/hyx/banner.png') no-repeat;
      background-size: 100% 100%;

      .title {
        font-size: 52px;
        font-weight: 800;
        margin: 170px 0 46px 0;
      }

      .sub-title {
        font-size: 30px;
        font-weight: 500;
        color: #2C3A50;
      }
    }
  }
}

.hongbao {
  background-color: #fff;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .card-warp {
    position: relative;
    margin-bottom: -200px;
    .card {
      width: 400px;
      height: 540px;
      box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
      border-radius: 20px;
      border: 2px solid #F5F7FB;
      background-color: #fff;

      .header {
        height: 130px;
        background-image: url('~@/assets/images/hyx/head-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        img {
          width: 26px;
          height: 26px;
        }
      }

      .line {
        width: calc(100% - 40px);
        border-bottom: 1px solid #F5F7FA;
      }

      .dui {
        width: 18px;
        height: 14px;
      }

      .right-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
  .bottom {
    height: 350px;
    background-image: url('~@/assets/images/hyx/bg.png');
    background-repeat: no-repeat;
    justify-content: end;
    letter-spacing: 10px;
  }

}

.product {
  background-color: #F5F7FA;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  img {
    width: 1200px;
    margin: 0 auto 100px;
  }
}

.cooperate {
  background-color: #fff;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }

  .card-warp {
      margin-bottom: 90px;
      .card {
        width: 380px;
        height: 200px;
        background-image: linear-gradient(0deg, #fff, #f3f5f8);
        box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
        border-radius: 4px;
        border: 2px solid #FFFFFF;
        padding: 30px 30px 0;
        .icon {
          width: 30px;
          height: 30px;
        }
    }
  }
}
</style>