<template>
  <div class="home-warp">
    <div class="banner">
      <a-carousel >
        <div class="banner-img">
          <div class="layout">
            <div class="color-primary title">构建用户数字生活营销生态圈</div>
            <div class="sub-title">推进品牌建设，助力企业运营新增长</div>
          </div>
        </div>
        <div class="banner-img banner-img2">
          <div class="layout">
            <div class="color-primary title">数字营销与权益服务平台</div>
            <div class="sub-title">活动发放→场景核销→票税处理→代运营→营销导流</div>
          </div>
        </div>
      </a-carousel>
    </div>

    <div class="number flex-c">
      <div class="title">致力于数字营销与权益服务</div>
      <div class="layout content flex-r">
          <div class="item">
            <div class="color-primary font-22 mb-20 font-weight-bold">
              <span class="color-blue font-38"><count-to :end-val="10" :duration="duration"/></span> 亿元+
            </div>
            <div class="color-gary font-16">数字营销年核销规模</div>
          </div>
          <div class="item">
            <div class="color-primary font-22 mb-20 font-weight-bold">
              <span class="color-blue font-38"><count-to :end-val="2000" :duration="duration" separator=""/></span> 亿元+
            </div>
            <div class="color-gary font-16">年处理企业、银行合作支付金额 </div>
          </div>
          <div class="item">
            <div class="color-primary font-22 mb-20 font-weight-bold">
              <span class="color-blue font-38"><count-to :end-val="1000" :duration="duration" separator=""/></span> 家+
            </div>
            <div class="color-gary font-16">服务企业及银行 </div>
          </div>
          <div class="item">
            <div class="color-primary font-22 mb-20 font-weight-bold">
              <span class="color-blue font-38"><count-to :end-val="6" :duration="duration" /></span> 年
            </div>
            <div class="color-gary font-16">数字行业经验 </div>
          </div>
          <div class="item">
            <div class="color-primary font-22 mb-20 font-weight-bold">
              <span class="color-blue font-38"><count-to :end-val="300" :duration="duration" /></span> 亿人+
            </div>
            <div class="color-gary font-16">服务个人 </div>
          </div>
          <div class="item">
            <div class="color-primary font-22 mb-20 font-weight-bold">
              <span class="color-blue font-38"><count-to :end-val="200" :duration="duration" /></span> 亿家+
            </div>
            <div class="color-gary font-16">合作企业 </div>
          </div>
      </div>
      <div class="layout card-warp flex-r justify-between">
        <div class="card flex-c justify-center">
          <div class="flex-r items-center mb-10">
            <img class="icon" src="@/assets/images/icons/icon1.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">7×24小时</span>
          </div>
          <div class="font-16 color-gary">硬件/软件/网络风险实时预警</div>
        </div>
        <div class="card flex-c justify-center">
          <div class="flex-r items-center mb-10">
            <img class="icon" src="@/assets/images/icons/icon2.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">自研架构</span>
          </div>
          <div class="font-16 color-gary">可靠多级加密密钥体系</div>
        </div>
        <div class="card flex-c justify-center">
          <div class="flex-r items-center mb-10">
            <img class="icon" src="@/assets/images/icons/icon3.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">安全稳定</span>
          </div>
          <div class="font-16 color-gary">混合云部署、云防火墙</div>
        </div>
        <div class="card flex-c justify-center">
          <div class="flex-r items-center mb-10">
            <img class="icon" src="@/assets/images/icons/icon4.png" alt="">
            <span class="font-22 font-weight-bold color-333 ml-10">数据备份</span>
          </div>
          <div class="font-16 color-gary">数据库有严格备份策略，预防丢失</div>
        </div>
      </div>
    </div>

    <div class="product flex-c">
      <div class="title">了解我们的产品</div>
      <div class="layout flex-r items-center">
        <div class="left">
          <div class="font-26 color-primary mb-20">慧银销平台</div>
          <div class="font-16 color-333 mb-20">慧银销面向银行及中大型企业打造的数字营销与权益服务平台，一站式提供活动发放能力，场景核销能力，票税处理能力，代运营能力和营销导流能力；</div>
          <router-link class="color-blue font-16" :to="{name: 'Hyx'}">了解更多 ></router-link>
        </div>
        <img class="product-img" src="@/assets/images/product.png" alt="">
      </div>
    </div>

    <div class="hyx-warp flex-r items-center justify-center">
      <img class="htx-img" src="@/assets/images/hyx.png" alt="">
      <div class="right">
        <div class="font-26 color-primary mb-20">慧银销小程序</div>
        <div class="font-16 color-333">慧银销面向个人打造本地生活聚合优惠支付平台，依托银行 和大型企业营销活动与权益，为个人用户提供本地生活折上 折服务。</div>
      </div>
    </div>

    <div class="server flex-c">
      <div class="title">完善的服务能力</div>
      <div class="layout flex-r card-warp">
        <div class="card flex-r items-center">
          <div class="left">
            <div class="color-333 font-22 font-weight-bold mb-10">品牌贴牌</div>
            <div class="color-gary font-16">一键输出，轻装上线，快速打造 企业及银行自我品牌的营销平台</div>
          </div>
          <img class="icon" src="@/assets/images/icons/icon5.png" alt="">
        </div>
        <div class="card flex-r items-center">
          <div class="left">
            <div class="color-333 font-22 font-weight-bold mb-10">输出模式</div>
            <div class="color-gary font-16">SaaS平台+（小程序/公众号/H5）</div>
          </div>
          <img class="icon" src="@/assets/images/icons/icon6.png" alt="">
        </div>
        <div class="card flex-r items-center">
          <div class="left">
            <div class="color-333 font-22 font-weight-bold mb-10">发放能力</div>
            <div class="color-gary font-16">后台定制化营销活动，营销数据 实时监控</div>
          </div>
          <img class="icon" src="@/assets/images/icons/icon7.png" alt="">
        </div>
        <div class="card flex-r items-center">
          <div class="left">
            <div class="color-333 font-22 font-weight-bold mb-10">核销能力</div>
            <div class="color-gary font-16">通过手机端小程序/H5/APP核销</div>
          </div>
          <img class="icon" src="@/assets/images/icons/icon8.png" alt="">
        </div>
        <div class="card flex-r items-center">
          <div class="left">
            <div class="color-333 font-22 font-weight-bold mb-10">开票能力</div>
            <div class="color-gary font-16">在后台对活动金额进行开票</div>
          </div>
          <img class="icon" src="@/assets/images/icons/icon9.png" alt="">
        </div>
        <div class="card flex-r items-center">
          <div class="left">
            <div class="color-333 font-22 font-weight-bold mb-10">运营能力</div>
            <div class="color-gary font-16">名企服务实力，7*24小时贴心 服务</div>
          </div>
          <img class="icon" src="@/assets/images/icons/icon10.png" alt="">
        </div>
      </div>
    </div>

    <div class="bank flex-c">
      <div class="title">众多银行、企业一致认可</div>
      <div class="layout nav flex-r mb-40">
        <div class="tab color-333" :class="{active: currentIndex === index}" :key="index"
          v-for="(item, index) in nav" @click="currentIndex = index">{{ item }}</div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 0">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                通联支付-通惠到手
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="mb-50 font-16">通联作为慧银销的贴牌商，做了自己的营销平台“通惠到手”，输出给各大银行，给各大银行做线上运营活动，助力银行促活拉新，帮助商家导流。</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">输出银行</div>
                  <div class="font-22">系统销售费</div>
                </div>
                <div class="item">
                  <div class="font-16">线上运营</div>
                  <div class="font-22">营销活动费</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right1"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 1">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                大连银行
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：借记卡开卡、睡眠卡促活、本地生活场景建设，政府促销费诉求 。</div>
              <div class="mb-50 font-16">输出模式：小程序+公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">立减金发放</div>
                  <div class="font-22">精准促活</div>
                </div>
                <div class="item">
                  <div class="font-16">营销活动运营</div>
                  <div class="font-22">新增办卡</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right2"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 2">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                交通银行
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：打造网点15分钟生活圈，促进银行卡获客和活跃，形成持卡人与商户BC联动。</div>
              <div class="mb-50 font-16">输出模式：公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">网点联动营销</div>
                  <div class="font-22">客户引流</div>
                </div>
                <div class="item">
                  <div class="font-16">引入优质商户</div>
                  <div class="font-22">特惠商圈</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right3"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 3">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                民生银行上海分行
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：打造网点3公里商户活动，促进银行卡获客和活跃，形成持卡人与商户BC联动。</div>
              <div class="mb-50 font-16">输出模式：公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">网点联动营销</div>
                  <div class="font-22">客户引流</div>
                </div>
                <div class="item">
                  <div class="font-16">引入优质商户</div>
                  <div class="font-22">特惠商圈</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right4"></div>
      </div>
      <div class="content flex-r" v-show="currentIndex == 4">
          <div class="left">
            <div class="left-content color-white">
              <div class="text mb-20">
                云南邮政金融
                <img class="img" src="@/assets/images/bank/comma.png" alt="">
              </div>
              <div class="font-16">合作诉求：打造网点3公里商户活动，促进银行卡获客和活跃，形成持卡人与商户BC联动。</div>
              <div class="mb-50 font-16">输出模式：公众号</div>
              <div class="flex-r items-center justify-between">
                <div class="item">
                  <div class="font-16">网点联动营销</div>
                  <div class="font-22">客户引流</div>
                </div>
                <div class="item">
                  <div class="font-16">引入优质商户</div>
                  <div class="font-22">特惠商圈</div>
                </div>
                <div class="item">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="right right5"></div>
      </div>
      <div class="scorll-warp">
        <div class="layout swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide1"></div>
          </div>
        </div>
        <div class="layout swiper-container" dir="rtl">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide2"></div>
          </div>
        </div>
        <div class="layout swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide3"></div>
          </div>
        </div>
        <div class="layout swiper-container" dir="rtl">
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide4"></div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/dist/css/swiper.min.css' // 注意这里的引入
import CountTo from 'vue-count-to'
export default{
  name: 'Home',
  components: {
    CountTo
  },
  data() {
    return {
      duration: 5000,
      currentIndex: 0,
      nav:['通联支付', '大连银行', '交通银行', '民生银行', '邮政银行']
    }
  },
  mounted() {
    new Swiper('.swiper-container', {
      speed: 21500, //匀速时间
      autoplay: {
        delay: 0,
        stopOnLastSlide: true,
        disableOnInteraction: true,
      },
      loop: true
    });
  }
  

}
</script>

<style scoped  lang="less">
.swiper-container .swiper-wrapper{
    -webkit-transition-timing-function: linear; /*之前是ease-out*/
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
}

.banner {
  .ant-carousel {
    .banner-img {
      width: 100%;
      height: 580px;
      background: url('~@/assets/images/banner/banner.png') no-repeat;
      background-size: 100% 100%;
      &.banner-img2 {
        background: url('~@/assets/images/banner/banner2.png') no-repeat;
      }
      .title {
        font-size: 52px;
        font-weight: 800;
        margin: 170px 0 46px 0;
      }

      .sub-title {
        font-size: 30px;
        font-weight: 500;
        color: #2C3A50;
      }
    }
  }
}

.number {
  background-color: #fff;
  .title {
    margin-top: 72px;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .content {
    height: 360px;
    background: url('~@/assets/images/bg-map.png') no-repeat;
    background-size: 100% 100%;
    padding: 40px 70px 0 70px;
    flex-wrap: wrap;
    .item {
      width: 33.333%;
      text-align: center;
    }
  }
  .card-warp {
    margin-bottom: -65px;
      .card {
      width: 290px;
      height: 130px;
      background-image: linear-gradient(0deg, #fff, #f3f5f8);
      box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      padding-left: 26px;
      .icon {
        width: 22px;
        height: 22px;
      }
    }
  }
  
}

.product {
  background-color: #F5F7FA;
  padding-bottom: 80px;
  .title {
    margin-top: 140px;
    margin-bottom: 70px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }

  .left {
    width: 460px;
  }
  .product-img {
    width: 470px;
    height: 420px;
    margin-left: 180px;
  }
}

.hyx-warp {
  background-color: #fff;
  height: 600px;
  .hyx-img {
    width: 520px;
    height: 410px;
  }
  .right {
    width: 380px;
    margin-left: 160px;
  }
}

.server {
  background-color: #F5F7FA;
  padding-bottom: 70px;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .card-warp{
    flex-wrap: wrap;
    .card {
      width: 380px;
      height: 150px;
      background-image: linear-gradient(0deg, #fff, #f3f5f8);
      box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      margin-right: 30px;
      margin-bottom: 30px;
      padding: 0 30px 0 26px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .icon {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        margin-left: 40px;
      }
    }
  }
  
}

.bank {
  background-color: #fff;
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 38px;
    color: #111111;
    text-align: center;
  }
  .nav {
    border-bottom: 1px solid #F0F0F1;
    .tab {
      font-size: 18px;
      flex:1;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      &::after {
          position: absolute;
          content: '';
          bottom: 0;
          width: 100%;
          height: 3px;
          left: 0;
          transform: scaleX(0);
          background-color: #0089FA;
          transition: all 0.3s;
        }
      &.active {
        color: #0089FA;
        font-weight: bold;
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  .content {
    position: relative;
    height: 360px;
    .left {
      width: 50%;
      position: relative;
      background-color: #0089FA;
      .left-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        width: 600px;
        height: 100%;
        right: 0;
        padding-left: 48px;
        padding-right: 140px;
        .text {
          font-size: 22px;
          color: #fff;
          position: relative;
          .img {
            position: absolute;
            left: -48px;
            top: 0;
            width: 24px;
            height: 20px;
          }
        }
        .item {
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #82BFF8;
            margin-right: 10px;
          }
        }
        
      }
    }
    .right {
      width: 50%;
      height: 360px;
      background-repeat: no-repeat;
    }
    .right1 {
      background-image: url('~@/assets/images/bank/banner.png');
    }
    .right2 {
      background-image: url('~@/assets/images/bank/banner2.png');
    }
    .right3 {
      background-image: url('~@/assets/images/bank/banner3.png');
    }
    .right4 {
      background-image: url('~@/assets/images/bank/banner4.png');
    }
    .right5 {
      background-image: url('~@/assets/images/bank/banner5.png');
    }
  }

  .scorll-warp {
    overflow: hidden;
    margin-top: 60px;
    margin-bottom: 50px;
    .swiper-slide {
      height: 87px; 
      background-size: contain;
      background-repeat: no-repeat;
    }
    .swiper-slide1 {
      background-image:url('~@/assets/images/bank/1.png');
    }
    .swiper-slide2 {
      background-image:url('~@/assets/images/bank/2.png');
    }
    .swiper-slide3 {
      background-image:url('~@/assets/images/bank/3.png');
    }
    .swiper-slide4 {
      background-image:url('~@/assets/images/bank/4.png');
    }
  }


}
</style>
