<template>
  <div class="hxf-warp">
      慧选付
    <div v-for="n in 50" :key="n">{{ n }}</div>
  </div>
</template>

<script>
export default {
  name: 'Hxf',
}
</script>
